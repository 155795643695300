import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardBody, Typography, Tooltip } from "@material-tailwind/react";


const BackButton = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(-1);
  };

  return (
    <div className="my-0 px-0 mb-8 text-xs backarea">
    <a
      className="back-button rounded-b-lg bg-black bg-opacity-80 uppercase font-bold px-3 py-1 text-white"
      onClick={handleClick}
    >
      Back
    </a>
    </div>
  );
};

export default BackButton;
