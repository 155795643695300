import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Leagues from './components/Leagues';
import Teams from './components/Teams';
import TeamPage from './components/TeamPage'; // add this line to import TeamPage
import Footer from "./components/Footer";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Leagues />} />
        <Route path="/league/:leagueName" element={<Teams />} />
        <Route path="/teams/:teamName" element={<TeamPage />} /> // Add this new route
      </Routes>
            <Footer />

    </Router>
  );
}

export default App;
