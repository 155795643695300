import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import sportsData from '../sportsData.json';
import HeaderNav from '../HeaderNav';
import SingleHeroSection from './SingleHeroSection';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Card, CardBody, Typography, Tooltip } from "@material-tailwind/react";
import ShrinkText from './ShrinkText';
import BackButton from './BackButton';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';


const TeamPage = () => {
  const { teamName } = useParams();
  const team = sportsData.flatMap((league) => league.teams).find((t) => t.name === teamName);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [navigate]);

  const [copied, setCopied] = useState(false);

  const colorStyles = {
    width: '100%',
    height: '4rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '1rem',
    borderRadius: '0.5rem',
    position: 'relative', // add relative positioning for the tooltip
  };

  const colorTextStyles = {
    color: 'white',
    fontWeight: 'bold',
    textShadow: '1px 1px #000',
  };

  const handleCopy = (color) => {
    setCopied(color);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return (
    <div className="tailwind">
          <Helmet>
        <title>{teamName} - SportsHex</title>
        <meta name="description" content={team.blurb} />
      </Helmet>
      <HeaderNav />
      <SingleHeroSection team={team} />

      <div className="min-h-screen bg-gray-100">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <BackButton />
          <div className="grid grid-cols-1 sm:grid-cols-4 gap-4">
<Card className="bg-gradient-to-t from-white via-white to-gray-100" style={{ borderTop: `7px solid ${team.colors[0]}` }}>
                <CardBody>
            <div className="sm:col-span-1">
              <Typography variant="h4" color="black" className="font-black"  style={{ marginBottom: '1rem' }}>Current Team Colors</Typography>
              {team.colors.map((color, index) => (
                <CopyToClipboard key={index} text={color} onCopy={() => handleCopy(color)}>
                  <div className="teamcolor" style={{ ...colorStyles, backgroundColor: color }}>
                    <Typography style={colorTextStyles}>{copied === color ? 'Copied to clipboard' : color}</Typography>
                  </div>
                </CopyToClipboard>
              ))}
            </div>
            </CardBody>
            </Card>
            <div className="sm:col-span-3 pl-0 sm:pl-3">
              <Card className="bg-gradient-to-t from-white via-white to-gray-100" style={{ borderTop: `7px solid ${team.colors[1]}` }}>
                <CardBody>
                 <Typography variant="h4" color="black" className="font-black" style={{ marginBottom: '1rem' }}>Team Blurb</Typography>
                  <Typography className="text-black font-normal text-lg" color="black">{team.blurb}</Typography>
                </CardBody>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamPage;
