import React, { useState, useEffect } from 'react';
import { Breadcrumbs } from "@material-tailwind/react";

import sportsData from '../sportsData.json';
import { useParams } from 'react-router-dom';
import TeamCard from './TeamCard';
import '../TeamCard.css';
import { BreadcrumbsStyleTypes } from "@material-tailwind/react";


const TeamHeroSection = () => {
  const { leagueName } = useParams();
const league = sportsData.find(l => l.league === leagueName);
const leagueAbbreviation = league && league['abbreviation'];

  const [bottomGradientStyle, setBottomGradientStyle] = useState({});

  useEffect(() => {
    setBottomGradientStyle({
      background: `linear-gradient(135deg, rgba(255, 255, 255, 0), ${league['league-colors'][1]}, rgba(255, 255, 255, 0))`,
    });
  }, [league]);

  const backgroundStyle = {
    background: `linear-gradient(135deg, ${league['league-colors'][0]}, ${league['league-colors'][1]})`,
  };

  return (
    <section className="text-white py-10 sm:py-28 hero-section relative" style={backgroundStyle}>
      <div className="container mx-auto px-4">
        <div className="flex flex-col items-center">
        <h1 className="text-2xl md:text-5xl bg-black text-center font-black bg-opacity-60 py-2 px-3 sm:py-6 sm:px-8 text-white">{leagueName} ({leagueAbbreviation})</h1>
           <Breadcrumbs className="crumby hidden sm:flex rounded-b-lg bg-black bg-opacity-60 text-white">
            <a href="../" className="opacity-100 text-white font-xs">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
              </svg>
            </a>
            {leagueName && (
              <a href="#" className="opacity-100 leaguelink text-white text-xs ">
                <span>{leagueName}</span>
              </a>
            )}
          
          </Breadcrumbs>
        </div>
      </div>
      <div className="absolute bottom-0 left-0 right-0 h-1 sm:h-1" style={bottomGradientStyle}></div>
    </section>
  );
};

export default TeamHeroSection;