import React, { useState, useEffect } from 'react';
import sportsData from '../sportsData.json';
import { Breadcrumbs } from "@material-tailwind/react";
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { BreadcrumbsStyleTypes } from "@material-tailwind/react";


const SingleHeroSection = () => {
  const { teamName } = useParams();
  const team = sportsData.flatMap((league) => league.teams).find((t) => t.name === teamName);

  const [bottomGradientStyle, setBottomGradientStyle] = useState({});
  const [leagueName, setLeagueName] = useState('');

  useEffect(() => {
    setBottomGradientStyle({
      background: `linear-gradient(135deg, rgba(255, 255, 255, 0), ${team.colors[1]}, rgba(255, 255, 255, 0))`,
    });

    // Find the league name for the current team
    const league = sportsData.find((league) =>
      league.teams.some((t) => t.name === teamName)
    );
    if (league) {
      setLeagueName(league.league);
    }
  }, [team, teamName]);

  const backgroundStyle = {
    background: `linear-gradient(45deg, ${team.colors[0]}, rgba(0,0,0,0.9))`,
  };

  return (
    <section className="text-white py-10 sm:py-28 hero-section relative" style={backgroundStyle}>
      <div className="container mx-auto px-4">
        <div className="flex flex-col items-center">
      
        <h1 className="text-2xl md:text-5xl bg-black text-center font-black bg-opacity-60 py-2 px-3 sm:py-6 sm:px-8 text-white">{teamName}</h1>

         <Breadcrumbs className="hidden sm:flex crumby rounded-b-lg bg-black bg-opacity-60 text-white">
            <a href="../" className="opacity-100 text-white">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
              </svg>
            </a>
            {leagueName && (
              <a href={`../league/${leagueName}`} className="opacity-100 text-xs leaguelink text-white">
                <span>{leagueName}</span>
              </a>
            )}
            <a className="text-white text-xs " href="#">{teamName}</a>
          </Breadcrumbs>
        </div>
      </div>
      <div className="absolute bottom-0 left-0 right-0 h-2 sm:h-1" style={bottomGradientStyle}></div>
    </section>
  );
};

export default SingleHeroSection;
