import React from 'react';
import { useNavigate } from 'react-router-dom';
import sportsData from '../sportsData.json';

const HeroSection = () => {
  const navigate = useNavigate();

  const handleRandomTeamClick = () => {
    const teams = sportsData.flatMap((league) => league.teams);
    const randomIndex = Math.floor(Math.random() * teams.length);
    const randomTeam = teams[randomIndex];
    navigate(`/teams/${randomTeam.name}`);
  };

  return (
    <section className="bg-gradient-to-r from-blue-500 via-purple-600 via-green-600 to-red-500 text-white py-20">
      <div className="container mx-auto px-4">
        <div className="flex flex-col py-2 items-center">
          <h1 className="text-4xl md:text-6xl font-black mb-4">SportsHex</h1>
          <p className="text-xl md:text-2xl mb-6 text-center">
            Discover hex color codes for your favorite professional sports league teams
          </p>
          <button
            className="bg-white text-gray-800 font-semibold py-3 px-8 rounded-md hover:bg-gray-200 transition-colors duration-300"
            onClick={handleRandomTeamClick}
          >
            Random Team
          </button>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
