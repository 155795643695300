import React from 'react';
import sportsData from '../sportsData.json';
import { Link } from 'react-router-dom';
import { Card, CardBody, Typography, Tooltip } from "@material-tailwind/react";
import HeaderNav from '../HeaderNav';
import HeroSection from './HeroSection';

import '../TeamCard.css';

const Leagues = () => {
  return (
    <div className="tailwind">
      <HeaderNav />
      <HeroSection />
    <div className="min-h-screen bg-gray-100 bg-gradient-to-t from-white via-white to-gray-300 ">
      <div className="py-16 px-4 sm:px-6 lg:px-8 container mx-auto ">
        <h1 className="text-4xl font-extrabold text-center text-gray-900">Leagues</h1>
        <div className="mt-12 grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-4">
          {sportsData.map((league, index) => (
         
    <Card className="justify-center leaguelinks" style={{ borderTop: `7px solid    ${league['league-colors'][0]}  ` }}>

            <Link
              key={index}
              to={`/league/${league.league}`}
              className="group block p-4 "
            >
              <img
                src={`https://kplantar.sirv.com/Images/${league.abbreviation}.webp`}
                alt={league.league}
                height="105px"
                width="245px"
                className="mx-auto league-img max-w-fit max-h-fit "
              />
              <p className="mt-4 text-xl font-semibold text-center text-gray-900 group-hover:text-indigo-600 league-name">
                {league.league}
              </p>
            </Link>
            </Card>
          ))}
        </div>
      </div>
    </div>
    </div>
  );
};

export default Leagues;
