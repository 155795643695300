import React from "react";

export default function Footer() {
  return (
    <footer className="bg-gray-900 text-gray-400 py-4">
      <div className="max-w-screen-xl mx-auto px-4">
        <p className="text-center">2023 SportsHex | <a href="mailto:email@email.com">Contact</a> | <a href="#"> Privacy Policy </a> </p>
      </div>
    </footer>
  );
}
