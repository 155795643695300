import React, { useState, useRef, useEffect } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Card, CardBody, Typography, Tooltip } from "@material-tailwind/react";
import ShrinkText from './ShrinkText';
import { useNavigate, useLocation } from 'react-router-dom';

import '../TeamCard.css';

const TeamCard = ({ team }) => {
  const [tooltipColor, setTooltipColor] = useState('');
  const [copied, setCopied] = useState(false); // Add a state for copied status
  const teamCardRef = useRef(null);
  const [teamCardWidth, setTeamCardWidth] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (teamCardRef.current) {
      setTeamCardWidth(teamCardRef.current.offsetWidth);
    }
    window.scrollTo(0, 0); // Scroll to the top of the page when the location changes
  }, [teamCardRef, location]);

  const handleTeamClick = () => {
      navigate(`/teams/${team.name}`);
  };

  const backgroundStyle = {
    background: `linear-gradient(135deg, ${team.colors[0]}, rgba(0,0,0,0.9))`,
    color: '#fff',
    borderBottom: `7px solid ${team.colors[1]}`,
  };

  const handleCopy = (color) => {
    setCopied(color);
    setTimeout(() => {
      setCopied(false);
    }, 2000); // Clear copied message after 2 seconds
  };

  return (
    <Card className="auto"  style={{ cursor: 'pointer' }}>
      <CardBody ref={teamCardRef} className="py-4 px-5 text-center teamcard rounded-xl" style={backgroundStyle}>
        <ShrinkText parentWidth={teamCardWidth}>
          <Typography onClick={handleTeamClick} className="text-xl left-text text-white font-black mb-2">
            {team.name}
          </Typography>
        </ShrinkText>
        <div className="flex justify-end">
          {team.colors.map((color, index) => (
            <CopyToClipboard key={index} text={color}>
              <div>
                <Tooltip
                  content={copied === color ? `${color} Copied to Clipboard` : color}
                  style={{ backgroundColor: color }}
                  animate={{
                    mount: { scale: 1, y: 0 },
                    unmount: { scale: 0, y: 25 },
                  }}
                >
                  <div
                    style={{
                      backgroundColor: color,
                    }}
                    className="w-8 h-8 colorSwatch rounded cursor-pointer team-col ml-2"
                    onMouseEnter={() => setTooltipColor(color)}
                    onMouseLeave={() => setTooltipColor('')}
                    onClick={() => handleCopy(color)} // Call handleCopy when the div is clicked
                  ></div>
                </Tooltip>
              </div>
            </CopyToClipboard>
          ))}
        </div>
      </CardBody>
    </Card>
  );
};

export default TeamCard;
