import React from 'react';
import sportsData from '../sportsData.json';
import { useParams } from 'react-router-dom';
import TeamCard from './TeamCard';
import HeaderNav from '../HeaderNav';
import TeamHeroSection from './TeamHeroSection';
import BackButton from './BackButton';

const Teams = () => {
  const { leagueName } = useParams();
  const league = sportsData.find((l) => l.league === leagueName);

  return (
    <div className="tailwind">
              <HeaderNav />
              <TeamHeroSection />
    <div className="min-h-screen bg-gray-100 bg-gradient-to-t from-white via-white to-gray-300">
      <div className="container mx-auto pb-8 px-4 sm:px-6 lg:px-8 ">

      <BackButton />
        <div className="mt-3 grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-4">
          {league.teams.map((team, index) => (
            <TeamCard key={index} team={team} />
          ))}
        </div>
      </div>
    </div>
    </div>
  );
};

export default Teams;
