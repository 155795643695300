import React, { useRef, useEffect, cloneElement } from 'react';

const ShrinkText = ({ children }) => {
  const containerRef = useRef(null);
  const textRef = useRef(null);

  const adjustFontSize = () => {
    const container = containerRef.current;
    const textElement = textRef.current;
    let fontSize =20;

    textElement.style.fontSize = fontSize + 'px';

    while (textElement.offsetWidth > container.offsetWidth) {
      fontSize--;
      textElement.style.fontSize = fontSize + 'px';
    }
  };

  useEffect(() => {
    adjustFontSize();
    window.addEventListener('resize', adjustFontSize);
    return () => window.removeEventListener('resize', adjustFontSize);
  }, []);

  return (
    <div className="container-right" ref={containerRef}>
      {cloneElement(children, { ref: textRef })}
    </div>
  );
};

export default ShrinkText;
