import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBasketballBall, faFutbol, faHockeyPuck, faFootballBall } from '@fortawesome/free-solid-svg-icons';
import React, { useState, useEffect } from "react";
import sportsData from "./sportsData.json";
import './TeamCard.css';
import { Link } from "react-router-dom";

function SportsDropdown({ sport }) {
  const sportIcon = {
    basketball: { icon: faBasketballBall, color: '#8B4513' },
    soccer: { icon: faFutbol, color: '#000' },
    hockey: { icon: faHockeyPuck, color: '#2A2A2A' },
    football: { icon: faFootballBall, color: '#D50A0A' },
  };

  const leagues = sportsData.filter(item => item.sport === sport);
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const dropdownClassName = isOpen ? 'dropdown-menu' : 'dropdown-menu hidden';

  return (
    <div className="dropdown">
      <button className="dropdown-button mx-1" onClick={toggleDropdown}>
        <FontAwesomeIcon icon={sportIcon[sport].icon} color={sportIcon[sport].color} />
      
      </button>
      <div className={dropdownClassName}>
        {leagues.map(({ league }) => (
          <Link key={league} to={`/league/${league}`} className="dropdown-item" onClick={toggleDropdown}>
            <span>{league}</span>
          </Link>
        ))}
      </div>
    </div>
  );
}

function NavList() {
  const sports = ["hockey", "football", "soccer", "basketball"];

  return (
    <ul className="flex flex-wrap justify-center gap-2 sm:flex-row sm:justify-center">
      {sports.map((sport) => (
        <SportsDropdown key={sport} sport={sport} />
      ))}
    </ul>
  );
}

export default function ComplexNavbar() {
  return (
    <nav className="mx-auto max-w-screen-xl p-2 lg:rounded-full lg:pl-6">
      <div className="relative mx-auto flex flex-col items-center text-blue-gray-900 sm:flex-row">
        <div className="sitelogo w-full sm:w-auto mb-1 sm:mb-0">
          <a href="../">
            <span className="mr-4 w-full ml-2 cursor-pointer py-1.5 text-3xl sm:text-2xl font-black">
              SPORTSHEX
            </span>
          </a>
        </div>
        <div className="ml-auto w-full sm:w-auto inline">
          <NavList />
        </div>
      </div>
    </nav>
  );
}
